<template>
  <v-container fluid>
    <!-- start of hardcoded footer -->

    <v-row>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          readonly
          label="External Reference"
          v-model="ExtRef"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="SlpCode"
          label="Sales Employee"
          :items="salesEmployees"
          item-text="SlpName"
          item-value="SlpCode"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-text-field
          type="number"
          outlined
          dense
          readonly
          label="Total Before Discount"
          v-model.number="totalBeforeDiscount"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          outlined
          dense
          filled
          readonly
          v-model="OwnerCode"
          :items="ohems"
          label="Document Owner"
          :item-text="item => item.firstName +'    '+ item.lastName"
          item-value="empID"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-textarea
          outlined
          v-model="Comments"
          label="Remarks"
          document_lines="4"
        ></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          autocomplete="nope"
          :items="transferType"
          item-text="name"
          item-value="value"
          label="Transfer Type"
          v-model="U_transferType"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          v-model="U_DemoLocation"
          label="Demo Location"
        >
        </v-text-field>
      </v-col>

    </v-row>

    <!-- end of hardcoded footer -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    salesEmployees: {
      type: Array,
    },
    docTotal: {
      type: Number,
    },
    genDiscount: {
      type: Number,
    },
    discountAmount: {
      type: Number,
    },

    finalTotalAmount: {},
    genTotalTax: {
      type: Number,
    },
    DefaultSlp: {
      type: String,
    },
    externalRefrence: {
      type: String,
    },
    initial: {
      type: Object,
    },
    dealerDiscount: {
      type: Number,
    },
    withPayment: {
      type: String,
    },
    footerFields: {
      type: Array,
    },
    activeObject: {
      type: String,
    },
    docCreate: {
      type: Boolean,
    },
  },
  data() {
    return {
      record: {},
      discount: null,
      docReadonly: "N",
      trueValue: true,
      falseValue: false,
      SlpCode: null,
      U_SalePipe: null,
      U_ServiceCall: null,
      U_DemoLocation: null,
      U_MpesaRefNo: null,
      U_Technician: null,
      U_Location: null,
      U_SSerialNo: null,
      U_transferType: null,
      U_TypePur: null,
      U_BaseDoc: null,
      U_NegativeMargin: "N",
      ExtRef: null,
      Comments: null,
      OwnerCode: null,
      //employees: [],
      U_PCash: null,
      paymentDialog: false,
      paymentListDialog: false,
      cheques: [],
      CashSum: 0,
      TrsfrSum: 0,
      CashAcct: null,
      TrsfrAcct: null,
      RoundDif: null,
      orct: [],
      creditcards: [],
      rct3: [],
      paymentData: {},
      PaidToDate: null,
      DocBalance: null,
      totalBeforeDiscount: 0,
      paymentList: [],
      pettyCash: [
        { name: "Petty Cash Request", value: "Petty Cash Request" },
        { name: "Purchase Request", value: "Purchase Request" },
        { name: "EFT/Cheque Request", value: "EFT/Cheque Request" },
        { name: "IOU Clearance", value: "IOU Clearance" },
        { name: "Expense Claim", value: "Expense Claim" },
        { name: "Casual Wages", value: "Casual Wages" },
        { name: "Cheque Payment Voucher", value: "Cheque Payment Voucher" },
        { name: "Petty Cash IOU Request", value: "Petty Cash IOU Request" },
      ],
      chequeHeaders: [
        { text: "Due Date", value: "DueDate" },
        { text: "Amount", value: "CheckSum" },
        { text: "Country", value: "CountryCod" },
        { text: "Bank Name", value: "BankCode" },
        { text: "Branch", value: "Branch" },
        { text: "Account", value: "AcctNum" },
        { text: "Cheque No.", value: "CheckNum" },
        { text: "Endorse", value: "Endorse" },
      ],
      salePipeLine: [
        { name: "Chomoka Na Gari", value: "Chomoka" },
        { name: "Roadshow", value: "Websites" },
        { name: "Referalls", value: "Referalls" },
        { name: "Adverts i.e Neswspaper", value: "Adverts" },
        { name: "Repeat Customer", value: "Repeat" },
      ],
      transferType: [
        { name: "Branch Transfer", value: "Branch" },
        { name: "Staff Technician", value: "Technician" },
        { name: "Van Sales", value: "VanSales" },
        { name: "Staff Demo", value: "StaffDemo" },
      ],
      purchaseType: [
        { name: "Import", value: "Import" },
        { name: "Import Clearing Charge", value: "ImportClearingCharge" },
        { name: "Laptop-Computer", value: "LaptopComputer" },
        { name: "Maintenance Material", value: "MaintenanceMaterial" },
        { name: "Mileage", value: "Mileage" },
      ],

      paymentListHeaders: [
        { text: "Payment No#", value: "DocNum" },
        { text: "Posting Date", value: "orct.DocDueDate" },
        { text: "Due Date", value: "orct.DocDate" },
        { text: "Total", value: "SumApplied" },
        { text: "SAP #", value: "orct.ExtRef" },
      ],
      negativeMargins: [
        { name: "Yes", value: "Y" },
        { name: "No", value: "N" },
      ],
      glAccounts: [],
      glCheckAccounts: [],
    };
  },

  watch: {
    dealerDiscount: {
      handler: "setDealerDiscount",
      immediate: true,
    },
    discount: {
      handler: "setDiscount",
      immediate: true,
    },
    OwnerCode: {
      handler: "setOwnerCode",
      immediate: true,
    },
    Comments: {
      handler: "setComments",
      immediate: true,
    },
    U_ServiceCall: {
      handler: "setServiceCall",
      immediate: true,
    },
    U_Technician: {
      handler: "setTechnician",
      immediate: true,
    },

    U_DemoLocation: {
      handler: "setDemolocation",
      immediate: true,
    },
    U_MpesaRefNo: {
      handler: "setMpesaRefNo",
      immediate: true,
    },
    U_SSerialNo: {
      handler: "serSerialNo",
      immediate: true,
    },
    U_Location: {
      handler: "setLocation",
      immediate: true,
    },
    SlpCode: {
      handler: "setSlp",
      immediate: true,
    },
    U_PCash: {
      handler: "setRequestType",
      immediate: true,
    },
    DefaultSlp: {
      handler: "setSlpCode",
      immediate: true,
    },
    ExtRef: {
      handler: "setExternalRef",
      immediate: true,
    },
    U_SalePipe: {
      handler: "setSalePipe",
      immediate: true,
    },
    U_transferType: {
      handler: "setTransferType",
      immediate: true,
    },
    U_TypePur: {
      handler: "setPurchaseType",
      immediate: true,
    },
    U_NegativeMargin: {
      handler: "setU_NegativeMargin",
      immediate: true,
    },
    externalRefrence: {
      handler: "setExternalRefCurrent",
      immediate: true,
    },
    initial: {
      handler: "setInitial",
      immediate: true,
    },
    paymentData: {
      handler: "sendPaymentData",
      immediate: true,
      deep: true,
    },
    CashAcct: {
      handler: "setCashAcct",
      immediate: true,
    },
    CashSum: {
      handler: "setCashSum",
      immediate: true,
    },
    TrsfrSum: {
      handler: "setTrsfrSum",
      immediate: true,
    },
    CheckSum: {
      handler: "setCheckSum",
      immediate: true,
    },

    TotalPaid: {
      handler: "setTotalPaid",
      immediate: true,
    },
    cheques: {
      handler: "setcheques",
      immediate: true,
    },
    rct3: {
      handler: "setCreditCard",
      immediate: true,
    },
    docTotal: {
      handler: "setDocTotal",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("ohem", ["ohems"]),
    saleItemsData() {
      return this.record.inv1;
    },
    TotalPaid() {
      const total =
        this.CheckSum +
        parseFloat(this.TrsfrSum) +
        parseFloat(this.CashSum) +
        this.CreditCardSum;
      return total;
    },
    TotalBalance() {
      const balance = this.TotalAmountDue - this.TotalPaid;
      return balance;
    },
    TotalAmountDue() {
      return this.finalTotalAmount;
    },
    CheckSum() {
      let total = 0;
      if (typeof this.cheques != "undefined" && this.cheques.length > 0) {
        return this.cheques
          .map((item) => {
            const amount = item.CheckSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    CreditCardSum() {
      let total = 0;
      if (this.rct3.length > 0) {
        return this.rct3
          .map((item) => {
            const amount = item.CreditSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    CurrentlyLoginUser() {
      return this.$store.state.user;
    },
    cashGLAccounts() {
      return this.glAccounts.filter(function (e) {
        if (e.Finanse == "Y") {
          return e;
        }
      });
    },
    employees() {
      return this.$store.state.salesEmployees;
    },
  },
  methods: {
    setInitial(val) {
      if (val) {
        this.Comments = val.Comments;
        this.discount = val.DiscPrcnt;
        this.SlpCode = val.SlpCode;

        this.PaidToDate = val.formattedPaidToDate;
        this.U_SSerialNo = val.U_SSerialNo;
        this.DocBalance = val.formattedBalance;
        this.paymentList = val.payment;
        this.U_transferType = val.U_transferType;
        if (!this.docCreate) {
          this.U_NegativeMargin = val.U_NegativeMargin;
          this.OwnerCode = val.OwnerCode;
        }
      }
    },
    setDealerDiscount() {},
    setDiscount(data) {
      this.$emit("discount", data);
    },
    setOwnerCode(data) {
      this.$emit("OwnerCode", data);
    },
    setComments(data) {
      this.$emit("Comments", data);
    },
    serSerialNo(data) {
      this.$emit("U_SSerialNo", data);
    },
    setServiceCall(data) {
      this.$emit("U_ServiceCall", data);
    },
    setDemolocation(data) {
      this.$emit("U_DemoLocation", data);
    },
    setMpesaRefNo(data) {
      this.$emit("U_MpesaRefNo", data);
    },
    setTechnician(data) {
      this.$emit("U_Technician", data);
    },

    setLocation(data) {
      this.$emit("U_Location", data);
    },
    setSlp(data) {
      this.$emit("SaleEmployee", data);
    },
    setSalePipe(val) {
      this.$emit("selectedSalePipe", val);
    },
    setTransferType(val) {
      this.$emit("U_transferType", val);
    },
    setPurchaseType(val) {
      this.$emit("U_TypePur", val);
    },
    setU_NegativeMargin(val) {
      this.$emit("setU_NegativeMargin", val);
    },
    setSlpCode(val) {
      if (val) {
        this.SlpCode = val;
      }
    },
    setRequestType(val) {
      this.$emit("U_PCash", val);
    },
    setExternalRef(val) {
      this.$emit("ExtRef", val);
    },
    setExternalRefCurrent(val) {
      if (val) {
        this.ExtRef = val;
      }
    },
    setDocTotal(val) {
      this.totalBeforeDiscount = 0;
      if (val) {
        this.totalBeforeDiscount = parseFloat(val.toFixed(2));
      }
    },
    addCheque(data) {
      this.cheques.push(data);
    },
    mpesaData(data) {
      this.rct3 = [];
      this.rct3.push(data);
    },
    setTransferDate(data) {
      const details = { TrsfrDate: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCashAcct(data) {
      const details = { CashAcct: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCashSum(data) {
      const details = { CashSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTrsfrAcct(data) {
      const details = { TrsfrAcct: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTrsfrSum(data) {
      const details = { TrsfrSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setcheques(data) {
      const details = { cheques: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCreditCard(data) {
      const details = { rct3: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setCheckSum(data) {
      const details = { CheckSum: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    setTotalPaid(data) {
      const details = { TotalPaid: data };
      this.paymentData = { ...this.paymentData, ...details };
    },
    sendPaymentData(data) {
      if (this.TotalPaid > 0) {
        this.$emit("salePaymentData", data);
      }
    },
    getActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.glAccounts = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    getCheckActiveGLAccounts() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/activeGLaccounts?type=Checks`)
        .then((res) => {
          self.glCheckAccounts = res;
        })
        .catch(() => {});
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/iemployee`)
        .then((res) => {
          self.employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    setDefaultOwnerCode() {
      this.OwnerCode = this.CurrentlyLoginUser.EmpID;
    },
    openPaymentDialog() {
      if (this.docCreate) {
        this.paymentDialog = true;
      }
      if (!this.docCreate) {
        this.paymentListDialog = true;
      }
    },
  },
  created() {
    this.getActiveGLAccounts();
    this.getCheckActiveGLAccounts();
    ///this.getEmployees();
    this.setDefaultOwnerCode();
  },
};
</script>

<style lang="scss" scoped>
</style>