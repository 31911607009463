<template>
  <v-container fluid id="docForm">
    <!-- with table -->
    <v-row v-if="tab.WithTable == 1">
      <v-container fluid>
        <!-- start of new item button -->

        <v-row dense>
          <v-col cols="4">
            <v-btn icon @click="openItemSerialModal">
              <v-icon>mdi-watermark</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row id="tableDataDiv">
          <v-col cols="12">
            <v-data-table
              light
              :fixed-header="trueValue"
              show-select
              hide-default-footer
              :single-select="singleSelect"
              v-model="selectedDocs"
              @item-selected="clickedDocs"
              item-key="id"
              :items-per-page="1000"
              :headers="visibleRows(tableRows)"
              :items="saleItemsData"
            >
              <!-- item code -->
              <template v-slot:item.ItemCode="{ item }">
                <v-text-field
                  single-line
                  style="width: 130px"
                  outlined
                  dense
                  v-model="item.ItemCode"
                  :append-icon="'mdi-airballoon'"
                  @click:append="openItemModal(item)"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Description -->
              <template v-slot:item.Dscription="{ item }">
                <v-text-field
                  single-line
                  outlined
                  dense
                  v-model="item.Dscription"
                  style="width: 300px"
                ></v-text-field>
              </template>
              <!-- end -->

              <!-- Quantity -->
              <template v-slot:item.Quantity="{ item }">
                <v-text-field
                  @input="inputChanged(item)"
                  single-line
                  outlined
                  dense
                  v-model="item.Quantity"
                  style="width: 100px"
                ></v-text-field>
              </template>

              <template v-slot:item.FromWhsCod="{ item }">
                <v-autocomplete
                  dense
                  outlined
                  v-model="item.FromWhsCod"
                  label="From Warehouse"
                  :items="warehouses"
                  item-value="WhsCode"
                  :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
                ></v-autocomplete>
              </template>

              <template v-slot:item.ToWhsCode="{ item }">
                <div class="card d-flex flex-row me-3 shadow">
                  <v-autocomplete
                    single-line
                    dense
                    outlined
                    v-model="item.ToWhsCode"
                    label="To Warehouse"
                    :items="warehouses"
                    item-value="WhsCode"
                    :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
                    style="width: 150px"
                  ></v-autocomplete>
                  <!--    @change="UpdateToBin(item)" -->
                  <!-- add calculator -->
                  <v-icon
                    @click="
                      UpdateToBin(item);
                      selectedRow = item;
                      openBinAllocModal(item);
                    "
                    >mdi-airballoon</v-icon
                  >
                </div>
              </template>

              <!-- Uom Code -->
              <template v-slot:item.UomCode="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.UomCode"
                  label="Uom Code"
                  :items="uoms"
                  item-text="uomentry.UomName"
                  item-value="uomentry.id"
                  style="width: 150px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode="{ item }">
                <!-- {{distributionRules1}} -->
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode"
                  :items="distributionRules1"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode2="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode2"
                  :items="distributionRules2"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode3="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode3"
                  :items="distributionRules3"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode4="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode4"
                  :items="distributionRules4"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.OcrCode5="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.OcrCode5"
                  :items="distributionRules5"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  @change="changedCostCenter(item)"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCod="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCod"
                  :items="distributionRules1"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo2="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo2"
                  :items="distributionRules2"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo3="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo3"
                  :items="distributionRules3"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo4="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo4"
                  :items="distributionRules4"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.CogsOcrCo5="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.CogsOcrCo5"
                  :items="distributionRules5"
                  :item-text="(item) => item.OcrCode + '  -  ' + item.OcrName"
                  item-value="OcrCode"
                  style="width: 190px"
                ></v-autocomplete>
              </template>

              <!-- Cost Center Code -->
              <template v-slot:item.U_Promotion="{ item }">
                <v-autocomplete
                  single-line
                  dense
                  outlined
                  v-model="item.U_Promotion"
                  :items="promototionsTypes"
                  item-text="name"
                  item-value="value"
                  style="width: 190px"
                ></v-autocomplete>
              </template>
              <!-- end -->
            </v-data-table>
            <v-btn
              style="margin-top: -100px"
              color="primary"
              class="mt-1"
              small
              dark
              @click="addTableRow()"
            >
              <v-icon left>mdi-plus</v-icon>Add Row
            </v-btn>
          </v-col>

          <!-- end of new item button -->
        </v-row>
      </v-container>
    </v-row>

    <v-row v-else-if="tab.WithTable == 2">
      <v-col
        v-for="(dataField, i) in tab.Fields"
        v-bind:key="i"
        :cols="dataField.ColumnWidth"
      >
        <v-text-field
          v-if="dataField.FieldType == 'textfield'"
          v-model="record[dataField.FieldName]"
          :label="dataField.Label"
        ></v-text-field>

        <text-area
          v-if="dataField.FieldType == 'textarea'"
          :setup="dataField"
        ></text-area>

        <v-autocomplete
          v-show="dataField.FieldType == 'select'"
          v-model="record[dataField.FieldName]"
          :label="dataField.Label"
        ></v-autocomplete>
      </v-col>

      <v-col cols="3">
        <v-textarea
          outlined
          v-model="JrnlMemo"
          label="Journal Remarks"
          document_lines="2"
        ></v-textarea>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          outlined
          v-model="UseShpdGd"
          label="Use Shipped Good Account"
          document_lines="6"
          true-value="Y"
          false-value="N"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row v-else>
      <attachment-editor
        @attachmentsSelected="setAttchments"
        :initialHeaderRecord="initialHeaderRecord"
      ></attachment-editor>
    </v-row>

    <!-- one item selection modal -->
    <v-dialog v-model="itemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="itemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Select using Item Code or Item Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- category quick add dialog -->
    <v-dialog v-model="deleteDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>DELETE ROW</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                Are you sure want you want to delete
                <span v-if="deleteItem">{{ deleteItem.Dscription }}</span
                >?
              </v-col>
              <v-col cols="12">
                <v-btn color="red" dark @click="deleteRow">Yes</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->

    <!-- start of serial modal -->
    <v-dialog
      v-model="serialDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="accent">
          <v-toolbar-title>
            <v-btn icon dark @click="serialDialog = false">
              <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
            >Serial Number Selection
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-1"
            outlined
            color="toolbarIcon"
            text
            dark
            @click="serialDialog = false"
          >
            <v-icon left dark>mdi-plus</v-icon>Save
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  @item-selected="itemsWithSerial"
                  :items-per-page="itemsPerPage"
                  v-model="selectedDocs"
                  :headers="serialHeaders"
                  :items="itemsManagedBySerial"
                  :single-select="singleSelect"
                  hide-default-footer
                  show-select
                  item-key="ItemCode"
                ></v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedSerialNumber"
                  :items-per-page="5"
                  v-model="selected"
                  :single-select="singleSelect"
                  :headers="serialSelectedHeaders"
                  :items="osrn"
                  item-key="id"
                  class="elevation-1"
                  :search="search"
                >
                  <!-- action template -->
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      @click="clickedSerialNumber(item)"
                      color="green"
                      icon
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <!-- end -->
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-card-title> Selected Serial Numbers </v-card-title>
                <v-data-table
                  @item-selected="removeSerialNumber"
                  :items-per-page="itemsPerPage"
                  v-model="selectedSerials"
                  :headers="serialSelectedHeaders"
                  :items="serialMasterData"
                  :single-select="singleSelect"
                  hide-default-footer
                  item-key="id"
                >
                  <!-- action template -->
                  <template v-slot:item.action="{ item }">
                    <v-btn @click="removeSerialNumber(item)" color="red" icon>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <!-- end -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of serial modal-->
    <!-- start price calculator modal -->
    <template>
      <div class="text-center pa-5">
        <v-dialog v-model="binAllocationModal" width="auto" :id="rowId"
          ><v-card width="450" height="400" class="mx-auto pa-5">
            <v-toolbar-title>Bin Allocations </v-toolbar-title>
            <template>
              <v-btn color="success" small @click="addField" class="my-5"
                >+</v-btn
              >
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(field, index) in fields"
                    :key="index"
                    :class="{
                      'd-flex': true,
                      'align-items-center': true,
                    }"
                  >
                    <v-autocomplete
                      dense
                      outlined
                      v-model="field.BinCode"
                      label="Bin Location"
                      :items="ToBinLocations"
                      item-value="BinCode"
                      item-text="BinCode"
                    ></v-autocomplete>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      v-model="field.QtyVar"
                      label="Quantity"
                    ></v-text-field>
                    <v-btn
                      class="mx-2"
                      color="warning"
                      small
                      @click="removeField(index)"
                      >X
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="binAllocationModal = false">
                  cancel
                </v-btn>
                <v-btn color="primary" @click="addBinAllocations()">Ok</v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <!-- end modal -->
    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>

    <!-- end of snackbar -->
  </v-container>
</template>


<script>
//Changs

import AttachmentEditor from "./attachment-editor.vue";

export default {
  components: {
    AttachmentEditor,
  },
  props: {
    tab: {
      type: Object,
    },
    selectedTenant: {
      type: Object,
    },
    initial: {
      type: Object,
    },
    initialRows: {
      type: Array,
    },
    NewBPLId: {
      type: String,
    },
    activeObject: {
      type: String,
    },
    dealerDiscount: {
      type: Number,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    GroupNum: {
      type: Number,
    },
    ToWhsCode: {
      type: String,
    },
    FromWhsCod: {
      type: String,
    },
  },
  data() {
    return {
      binAllocationModal: false,
      ToBinLocations: [],
      selectedRow: [],
      rowId: "",
      record: {},
      warehouses: [],
      uploadFiles: [],
      deleteItem: {},
      trueValue: true,
      falseValue: false,
      ManualValue: "Manual",
      searchItem: null,
      selectedBranch: null,
      singleSelect: true,
      advancedDialog: false,
      activeRow: null,
      DocType: null,
      saleItemsData: [],
      selectedItems: [],
      uoms: [],
      selectedDocs: [],
      taxGroups: [],
      glAccounts: [],
      costCenters: [],
      distributionRules1: [],
      distributionRules2: [],
      distributionRules3: [],
      distributionRules4: [],
      distributionRules5: [],
      itemsManagedBySerial: [],
      seriesData: [],
      itemRecord: {},
      selectedModalItem: {},
      itemDialog: false,
      deleteDialog: false,
      changeDocTypeDialog: false,
      lineTotalIsReadonly: true,
      unitPriceIsReadonly: true,
      itemsPerPage: 100,
      itemMasterData: [],
      JrnlMemo: "",
      UseShpdGd: null,
      attachments: [],
      initialHeaderRecord: [],
      fields: [
        {
          BinCode: "",
          QtyVar: "",
        },
      ],
      itemMasterHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "ItemName" },
        { text: "In Stock", value: "OnHand" },
      ],
      serialHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "Dscription" },
        { text: "Whse Code", value: "FromWhsCod" },
        { text: "Quantity", value: "Quantity" },
      ],
      allSerialHeaders: [{ text: "Serial", value: "DistNumber" }],

      serialSelectedHeaders: [
        { text: "Serial", value: "DistNumber" },
        { text: "LotNumber", value: "LotNumber" },
        { text: "Colour", value: "Colour" },
        { text: "Action", value: "action" },
      ],
      OcrCode: null,
      docTypes: [
        { name: "Items", value: "I" },
        { name: "Service", value: "S" },
      ],
      promototionsTypes: [
        { name: "Free Of Charge", value: "FOC" },
        { name: "Charged", value: "Charged" },
      ],
      tableRows: [],
      serialMasterData: [],
      serialDialog: false,
      search: null,
      selected: [],
      osrn: [],
      selectedSerials: [],

      discount: 0.0,
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
      deep: true,
    },
    saleItemsData: {
      handler: "sendSaleData",
      immediate: true,
      deep: true,
    },
    initialRows: {
      handler: "setInitialRows",
      immediate: true,
    },
    NewBPLId: {
      handler: "setSelectBranch",
      immediate: true,
    },
    JrnlMemo: {
      handler: "setJrnlMemo",
      immediate: true,
    },
    tab: {
      handler: "setTableRows",
      immediate: true,
      deep: true,
    },
    DocType: {
      handler: "selectedDocType",
      immediate: true,
    },
    attachments: {
      handler: "setFiles",
      immediate: true,
    },
    UseShpdGd: {
      handler: "setShpGd",
      immediate: true,
    },
    ToWhsCode: {
      handler: "setToWhsCode",
      immediate: true,
    },
    searchItem: {
      handler: "searchItemMasterData",
      immediate: true,
    },
  },
  computed: {
    saleItemsDataSerialManaged() {
      return this.saleItemsData.filter(function (e) {
        if (e.oitm) {
          if (e.oitm.ManSerNum == "Y") {
            return e;
          }
        }
      });
    },

    // globalWarehouses() {
    //   return this.$store.state.globalWarehouses;
    // },
  },
  methods: {
    // lineTotals,
    addField() {
      if (this.fields.length < this.ToBinLocations.length) {
        console.log(this.fields.length, this.ToBinLocations.length);
        this.fields.push({ value: "" });
      }
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    addBinAllocations() {
      let row = this.rowId;
      let index = this.saleItemsData.findIndex((findrow) => {
        return findrow.id === row;
      });

      console.log("Index " + index);

      if (this.saleItemsData.length > index && index >= 0) {
        this.saleItemsData[index].bin_allocation = this.fields;

        this.updateRows(this.saleItemsData);
        this.fields = [
          {
            BinCode: "",
            QtyVar: "",
          },
        ];
        console.log(this.saleItemsData);
      } else {
        console.log("Invalid rowId or empty saleItemsData");
      }
      this.binAllocationModal = false;
    },
    openBinAllocModal(item) {
      console.log(item.id);
      this.rowId = item.id;
      this.binAllocationModal = true;
    },
    setInitial(val) {
      if (val) {
        this.initialHeaderRecord = val;
        this.DocType = val.DocType;
        this.UseShpdGd = val.UseShpdGd;
        this.setDocTypeEffect();
      }
    },
    setInitialRows(val) {
      if (val) {
        if (this.DocType) {
          this.setDocTypeEffect();
          this.saleItemsData = [...val];
        }
      }
    },

    sendSaleData(data) {
      this.$emit("saleItemsData", data);
    },
    setJrnlMemo(data) {
      this.$emit("JrnlMemo", data);
    },
    setShpGd(data) {
      this.$emit("UseShpdGd", data);
    },
    selectedDocType(data) {
      this.setDocTypeEffect();
      this.$emit("docType", data);
    },
    setToWhsCode(data) {
      if (data) {
        if (this.saleItemsData.length > 0) {
          this.saleItemsData.forEach((doc) => {
            doc.ToWhsCode = this.ToWhsCode;
            this.updateRows(doc);
          });
        }
      }
    },
    setSelectBranch(val) {
      if (val) {
        const self = this;
        this.$store
          .dispatch("get", `/distribution-rules/1?branchID=${val}`)
          .then((res) => {
            self.OcrCode = res.ResponseData[0].OcrCode;
            self.distributionRules1 = res.ResponseData;
          })
          .catch((err) => {
            console.log(err);
          });
        this.$store
          .dispatch("get", `/warehouse?branchID=${val}`)
          .then((res) => {
            self.warehouses = res.ResponseData;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setDate() {},
    openItemModal(data) {
      if (this.DocType == null) {
        this.$refs.snackbar.show("Please select Doc Type first!", "red");
        return;
      }

      if (this.FromWhsCod == null) {
        this.$refs.snackbar.show("Please select warehouse first!", "red");
        return;
      }

      if (this.ToWhsCode == null) {
        this.$refs.snackbar.show("Please select warehouse first!", "red");
        return;
      }
      this.activeRow = data.id;
      this.selectedItems = [];
      this.itemDialog = true;
    },
    getData(data) {
      return this.$data[data];
    },
    visibleRows(data) {
      const document_lines = data.filter((record) => {
        return record.Visible === "Y";
      });

      return document_lines;
    },
    clickedDocs($event) {
      this.deleteItem = $event.item;
      this.deleteDialog = true;
    },
    updateModalItem() {
      const updatedItem = { ...this.selectedModalItem, ...this.itemRecord };
      const id = this.selectedModalItem.id;

      const currentItem = this.saleItemsData.find((record) => {
        return record.id === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      this.saleItemsData[pos] = updatedItem;
      this.itemRecord = {};
      this.advancedDialog = false;
    },
    updateRows(dataItem) {
      if (dataItem.ItemCode != null) {
        if (dataItem.DocEntry != null) {
          this.getItemDetails(dataItem);
        }
        // find item
        const currentItem = this.saleItemsData.find((record) => {
          return record.id === dataItem.id;
        });
        // end of find
        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }
        // update row
        this.saleItemsData[pos] = currentItem;
      }
    },
    inputChanged(data) {
      this.updateRows(data);
    },
    UpdateToBin(code) {
      console.log(code);
      let filteredWh = this.warehouses.find((record) => {
        return record.WhsCode == code.ToWhsCode;
      });
      this.ToBinLocations = filteredWh.binlocations;
      console.log(this.ToBinLocations);
    },
    changedCostCenter(item) {
      const id = item.id;
      // find the selected row and update
      const currentItem = this.saleItemsData.find((record) => {
        return record.id === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      const self = this;
      currentItem.CogsOcrCo2 = item.OcrCode2;
      currentItem.CogsOcrCo3 = item.OcrCode3;
      self.saleItemsData[pos] = currentItem;
    },
    changedUom(item) {
      const data = {
        ItemCode: item.id,
        CardCode: this.selectedTenant.id,
        SUoMEntry: item.UomCode,
        priceList: this.GroupNum,
      };

      const id = item.id;
      // find the selected row and update
      const currentItem = this.saleItemsData.find((record) => {
        return record.id === id;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      // end of tax get

      // make prices query request
      const self = this;
      const url = "/getDefaultPrice";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            const priceData = res.ResponseData;
            currentItem.PriceBefDi = priceData.FINALSALESPRICE;
            currentItem.StockPrice = priceData.FINALSALESPRICE;
            currentItem.NumPerMsr = priceData.SalesUnitINVUnitConversion;
            // get computed values
            const details = {
              Quantity: item.Qty || 1,
              PriceBefDi: priceData.FINALSALESPRICE,
            };

            const computedValues = self.lineTotals(details);
            currentItem.Price = computedValues.Price;
            currentItem.LineTotal = computedValues.LineTotal;
            currentItem.PriceAfVAT = computedValues.PriceAfVAT;
            currentItem.VatSum = computedValues.VatSum;
            currentItem.GTotal = computedValues.GTotal;

            // end of computation
          } else {
            self.$refs.snackbar.show(
              "Ensure the selected customer has a price List",
              "red"
            );
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
      // end of request

      // set defaults
      self.saleItemsData[pos] = currentItem;
    },
    clickedItem($event) {
      //Chnages
      if (this.selectedTenant.id !== undefined || this.activeObject !== 205) {
        this.selectedItem = $event.item;

        const sItem = $event.item;

        const data = {
          ObjType: this.activeObject, //Document Type
          // ItemCode: $event.item.id,
          ItemCode: sItem.ItemCode,
          CardCode: this.selectedTenant.id,
          priceList: this.GroupNum,
          SUoMEntry: null,
        };

        const id = this.activeRow;
        // find the selected row and update
        const currentItem = this.saleItemsData.find((record) => {
          return record.id === id;
        });

        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }

        // end of tax get

        currentItem.Dscription = sItem.ItemName;
        currentItem.ItemCode = sItem.ItemCode;
        currentItem.UomCode = sItem.SUoMEntry;
        currentItem.id = sItem.id;
        currentItem.Quantity = 1;
        currentItem.UseBaseUn = "N";
        currentItem.PackQty = 1;
        currentItem.PickStatus = "N";

        currentItem.IsManual = sItem.ougp.IsManual;

        currentItem.ManSerNum = sItem.ManSerNum; //To User to check for serial
        currentItem.oitm = sItem;
        // end of row find

        // make prices query request
        const self = this;
        const url = "/getDefaultPrice";
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              const priceData = res.ResponseData;
              currentItem.PriceBefDi = priceData.FINALSALESPRICE;
              currentItem.NumPerMsr = priceData.SalesUnitINVUnitConversion;

              //Current Inventory Details
              currentItem.oitw = priceData.oitw;
              //Dimensions Defaults
              currentItem.OcrCode = self.OcrCode;
              currentItem.CogsOcrCod = self.OcrCode;

              currentItem.OcrCode2 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode2
                : null;
              currentItem.CogsOcrCo2 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode2
                : null;

              currentItem.OcrCode3 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode3
                : null;
              currentItem.CogsOcrCo3 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode3
                : null;

              currentItem.OcrCode4 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode4
                : null;
              currentItem.CogsOcrCo4 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode4
                : null;

              currentItem.OcrCode5 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode5
                : null;
              currentItem.CogsOcrCo5 = priceData.ItemDimensionDfts
                ? priceData.ItemDimensionDfts.OcrCode2
                : null;

              // end of computation
            } else {
              self.$refs.snackbar.show(res.ResultDesc, "red");
            }
          })
          .catch((err) => {
            console.log(err, "err");
          });
        // end of request

        /**
         * Set Delaer Discount
         */

        currentItem.DiscPrcnt = this.discount > 0 ? this.discount : 0;

        // set defaults
        self.saleItemsData[pos] = currentItem;
        // end
        // fetch items uoms
        const UgpEntry = this.selectedItem.UgpEntry;
        this.$store
          .dispatch("get", `/uomgroup/${UgpEntry}`)
          .then((res) => {
            self.uoms = res.ResponseData.ugp1;
          })
          .catch((err) => {
            console.log(err, "error");
          });
        // end of uoms
        this.inputChanged(currentItem);
        // end of default values
        this.itemDialog = false;
      }
    },
    searchItemMasterData(val) {
      if (!val) {
        return;
      }

      this.fetchEntriesDebounced();
    },
    fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getItemMaster();
      }, 500);
    },
    getItemMaster() {
      this.loading = true;
      const self = this;
      this.$store
        .dispatch("get", `/item_masterdata?f=${this.searchItem}`)
        .then((res) => {
          self.itemMasterData = res.ResponseData;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      this.loading = false;
    },
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {
      console.log("Dialog closed");
    },
    addTableRow() {
      const itemsCount = this.saleItemsData.length;
      const newCount = itemsCount + 1;
      const details = {
        rowNum: new Date().getTime(),
        DocEntry: null,
        RowId: newCount,
        id: newCount,
        ItemCode: null,
        LineNum: null,
        Dscription: null,
        CodeBars: null,
        SerialNum: null,
        Quantity: null,
        DelivrdQty: null,
        UseBaseUn: null,
        PackQty: null,
        PriceBefDi: null,
        DiscPrcnt: null,
        Rate: null,
        Price: null,
        StockPrice: null,
        TaxCode: null,
        PriceAfVAT: null,
        LineTotal: null,
        WhsCode: null,
        ShipDate: null,
        SlpCode: null,
        Commission: null,
        TreeType: 1,
        LineStatus: null,
        TargetType: null,
        TargetEntry: null,
        BaseType: null,
        BaseRef: null,
        BaseEntry: null,
        BaseLine: null,
        SpecPrice: 1,
        VatSum: null,
        GrssProfit: null,
        PoTrgNum: null,
        BackOrdr: null,
        FreeTxt: null,
        PickStatus: null,
        UomCode: null,
        unitMsr: null,
        NumPerMsr: null,
        Text: null,
        GTotal: null,
        UgpCode: null,
        AgrNo: null,
        LinePoPrss: "N",
        OnHand: null,
        IsCommited: null,
        OnOrder: null,
        OpenQty: null,
        InvQty: null,
        OpenInvQty: null,
        OwnerCode: null,
        TrnsCode: null,
        OrigItem: null,
        GPTtlBasPr: null,
        GrossBuyPr: null,
        OcrCode5: null,
        OcrCode4: null,
        OcrCode3: null,
        OcrCode2: null,
        OcrCode: null,
        AcctCode: null,
        oitw: [],
        ManSerNum: null,
        ToWhsCode: this.ToWhsCode,
        FromWhsCod: this.FromWhsCod,
        U_Promotion: "Charged",
      };

      this.saleItemsData = [...this.saleItemsData, details];
    },

    getTaxGroups() {
      const self = this;

      if (this.activeObject == 205) {
        this.$store
          .dispatch("get", `/taxgroups/input`)
          .then((res) => {
            self.taxGroups = res;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$store
          .dispatch("get", `/taxgroups/output`)
          .then((res) => {
            self.taxGroups = res;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getCostCenters() {
      const self = this;
      this.$store
        .dispatch("get", `/cost-centers`)
        .then((res) => {
          self.costCenters = res.ResponseData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDistributionRules(id) {
      const self = this;
      this.$store
        .dispatch("get", `/distribution-rules/${id}`)
        .then((res) => {
          if (id == 1) {
            self.distributionRules1 = res.ResponseData;
          }
          if (id == 2) {
            self.distributionRules2 = res.ResponseData;
          }
          if (id == 3) {
            self.distributionRules3 = res.ResponseData;
          }
          if (id == 4) {
            self.distributionRules4 = res.ResponseData;
          }
          if (id == 5) {
            self.distributionRules5 = res.ResponseData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    generalSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          self.OcrCode = res.ResponseData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getWarehouses() {
    //   const self = this;
    //   this.$store
    //     .dispatch("get", `/warehouse?isForDoc=1`)
    //     .then((res) => {
    //       self.globalWarehouses = res.ResponseData;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    setAttchments(data) {
      this.attachments = data;
    },
    setFiles(data) {
      this.$emit("attachments", data);
    },
    deleteRow() {
      const self = this;
      const item = this.saleItemsData.find((record) => {
        return record.id === self.deleteItem.id;
      });
      const pos = this.saleItemsData.indexOf(item);
      if (pos === -1) {
        return;
      }
      this.saleItemsData.splice(pos, 1);
      this.deleteDialog = false;
    },

    setTableRows(data) {
      const self = this;
      self.tableRows = data.tableRows;
    },
    setDocType() {
      localStorage.removeItem("docType");
      localStorage.setItem("docType", this.DocType);
      if (this.saleItemsData.length > 0) {
        this.changeDocTypeDialog = true;
      } else {
        this.setDocTypeEffect();
      }
    },
    resetDocType() {
      if (this.DocType == "I") {
        this.saleItemsData = [];
        this.DocType = "S";
      }
      if (this.DocType == "S") {
        this.saleItemsData = [];
        this.DocType = "I";
      }

      this.changeDocTypeDialog = false;
    },

    setDocTypeEffect() {
      const self = this;
      this.changeDocTypeDialog = false;
      this.lineTotalIsReadonly = false;

      const fieldObjAcctCode = this.tableRows.find((record) => {
        return record.itemValue == "AcctCode";
      });
      const pos = this.tableRows.indexOf(fieldObjAcctCode);
      if (pos === -1) {
        return;
      }

      //Start of ItemCode

      const fieldObjItemCode = self.tableRows.find((record) => {
        return record.value === "ItemCode";
      });

      const posItemCode = this.tableRows.indexOf(fieldObjItemCode);
      if (posItemCode === -1) {
        return;
      }
      if (this.DocType == "I") {
        fieldObjItemCode.Visible = "Y";
      }
      if (this.DocType == "S") {
        fieldObjItemCode.Visible = "N";
      }

      this.tableRows[posItemCode] = fieldObjItemCode;
      //End Of ItemCode

      //Start of Quantity
      const fieldObjQuantity = self.tableRows.find((record) => {
        return record.value === "Quantity";
      });
      const posQuantity = this.tableRows.indexOf(fieldObjQuantity);
      if (posQuantity === -1) {
        return;
      }
      if (this.DocType == "I") {
        fieldObjQuantity.Visible = "Y";
      }
      if (this.DocType == "S") {
        fieldObjQuantity.Visible = "N";
      }
      this.tableRows[posQuantity] = fieldObjQuantity;
      //End Of ItemCode

      //Start UomCode
      const fieldObjUomCode = self.tableRows.find((record) => {
        return record.value === "UomCode";
      });
      const posUomCode = this.tableRows.indexOf(fieldObjUomCode);
      if (posUomCode === -1) {
        return;
      }

      if (this.DocType == "I") {
        this.unitPriceIsReadonly = false;
        fieldObjUomCode.Visible = "Y";
      }
      if (this.DocType == "S") {
        this.unitPriceIsReadonly = false;
        fieldObjUomCode.Visible = "N";
      }
      this.tableRows[posUomCode] = fieldObjUomCode;
      //End Of UomCode
    },
    getItemDetails(selectedItem) {
      // find item
      const currentItem = this.saleItemsData.find((record) => {
        return record.id === selectedItem.id;
      });

      if (selectedItem.oitm.oitw && selectedItem.oitm.oitw.length > 0) {
        currentItem.oitw = selectedItem.oitm.oitw;
        // end of find
        const pos = this.saleItemsData.indexOf(currentItem);
        if (pos === -1) {
          return;
        }

        this.saleItemsData[pos] = currentItem;
      }
    },
    openItemSerialModal() {
      const self = this;
      self.itemsManagedBySerial = [];
      if (this.activeObject == 66 || this.saleItemsData.length > 0) {
        self.itemsManagedBySerial = self.saleItemsDataSerialManaged;

        this.serialMasterData = [];
        this.selectedDocs = [];
        this.osrn = [];
        this.serialDialog = true;
      }
    },
    itemsWithSerial($event) {
      const self = this;
      var itemSelected = $event.item;

      if (self.selectedDocs.length > 0) {
        self.osrn = [];
        self.serialMasterData = [];
        return;
      }
      const itemID = itemSelected.oitm.id;

      if (!itemSelected.FromWhsCod) {
        this.$refs.snackbar.show("Select Warehouse First", "red");
        return;
      }

      // if (!this.initial.BaseType) {
      //   if (this.docCreate && itemSelected.U_StockWhse <= 0) {
      //     return;
      //   }
      // }

      if (
        itemSelected.SerialNumbers != null &&
        itemSelected.SerialNumbers.length > 0
      ) {
        itemSelected.SerialNumbers.forEach((doc) => {
          var BaseType = doc.BaseType;
          var BaseEntry = doc.BaseEntry;

          if (this.docCopyTo && this.initial.BaseType != 17) {
            if (this.initial.BaseType && this.initial.BaseEntry) {
              BaseType = this.initial.BaseType;
              BaseEntry = this.initial.BaseEntry;
            }
          }

          if (
            doc.BaseType == BaseType &&
            BaseType !== undefined &&
            doc.BaseEntry == BaseEntry &&
            this.initial.BaseType != 17
          ) {
            const details = {
              id: doc.osrn ? doc.osrn.id : null,
              DistNumber: doc.osrn.DistNumber,
              SysNumber: doc.osrn.SysNumber,
              SysSerial: doc.osrn.SysNumber,
              ItemCode: itemSelected.ItemCode,
              osrn: doc.osrn,
            };
            this.serialMasterData.push(details);

            return;
          }
          const details1 = {
            id: doc.osrn ? doc.osrn.id : null,
            DistNumber: doc.osrn ? doc.osrn.DistNumber : doc.DistNumber,
            SysNumber: doc.osrn ? doc.osrn.SysNumber : doc.SysNumber,
            SysSerial: doc.osrn ? doc.osrn.SysNumber : doc.SysNumber,
            ItemCode: itemSelected.ItemCode,
            osrn: doc,
          };
          this.serialMasterData.push(details1);
          return;
        });
      }

      if (this.initial.BaseType == 15 && this.activeObject == 16) {
        return;
      }

      if (this.activeObject == 15 && !this.docCreate) {
        return;
      }

      if (this.initial.BaseType == 15 && this.activeObject == 13) {
        return;
      }

      if (this.initial.BaseType == 13 && this.activeObject == 16) {
        return;
      }

      self.osrn = [];

      this.$store
        .dispatch(
          "get",
          `/item_masterdata/${itemID}?WhseCode=${itemSelected.FromWhsCod}`
        )
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.osrn = res.ResponseData.osrn;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickedSerialNumber(item) {
      var itemSelected = item;

      if (this.saleItemsData.length <= 0) {
        return;
      }

      // end of find
      const currentSerial = this.serialMasterData.find((record) => {
        return record.id === itemSelected.id;
      });

      if (currentSerial) {
        return;
      }

      // end of find
      const currentItem = this.saleItemsData.find((record) => {
        return record.ItemCode === itemSelected.ItemCode;
      });

      const pos = this.saleItemsData.indexOf(currentItem);
      if (pos === -1) {
        return;
      }

      var allSerialForItem = currentItem.SerialNumbers || [];
      if (allSerialForItem.length < currentItem.Quantity) {
        allSerialForItem.push(itemSelected);
        currentItem.SerialNumbers = allSerialForItem;
        this.saleItemsData[pos] = currentItem;
        this.serialMasterData = allSerialForItem;
      }
    },

    removeSerialNumber(item) {
      const self = this;

      // end of find
      const currentItem = self.saleItemsData.find((record) => {
        return record.ItemCode === item.ItemCode;
      });

      const posItem = self.saleItemsData.indexOf(currentItem);
      if (posItem === -1) {
        return;
      }

      var SerialNumbers = currentItem.SerialNumbers;
      const currentSerial = currentItem.SerialNumbers.find((record) => {
        return record.DistNumber === item.DistNumber;
      });

      const pos = self.serialMasterData.indexOf(currentSerial);
      if (pos === -1) {
        return;
      }

      self.serialMasterData.splice(pos, 1);
      SerialNumbers.splice(pos, 1);
      currentItem.SerialNumbers = SerialNumbers;
      this.saleItemsData[posItem] = currentItem;
    },
    setDefaults() {
      if (this.docCreate) {
        this.DocType = localStorage.getItem("docType");
      }
    },
  },
  created() {
    // this.getWarehouses();
    this.setDefaults();
    //  this.getItemMaster()
    // this.getCostCenters();
    this.getDistributionRules(1);
    this.getDistributionRules(2);
    this.getDistributionRules(3);
    this.getDistributionRules(4);
    this.getDistributionRules(5);
  },
};
</script>

