<template>
  <v-container fluid>
    <!-- start of header fields -->
    <v-row v-if="headerFields" dense>
      <v-col
        v-for="(dataField, i) in headerFields"
        v-bind:key="i"
        :cols="dataField.ColumnWidth"
        v-show="dataField.Visible == 'Y'"
      >
        <v-text-field
          dense
          outlined
          v-if="
            dataField.FieldType == 'textfield' &&
            dataField.FieldName == 'CardCode'
          "
          v-model="record[dataField.FieldName]"
          :label="dataField.Label"
          autocomplete="nope"
          :readonly="cardCodeIsReadOnly"
          :append-icon="'mdi-airballoon'"
          @click:append="openBp"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          v-else-if="
            dataField.FieldType == 'textfield' &&
            dataField.FieldName == 'DocNum'
          "
          :readonly="docReadonly == 'Y' ? trueValue : falseValue"
          autocomplete="nope"
          :filled="docReadonly == 'Y' ? trueValue : falseValue"
          v-model="DocNum"
          :label="dataField.Label"
        ></v-text-field>

        <v-text-field
          v-else-if="dataField.FieldName == 'NumAtCard'"
          dense
          outlined
          autocomplete="nope"
          v-show="dataField.FieldType == 'textfield'"
          v-model="NumAtCard"
          :label="dataField.Label"
        ></v-text-field>

        <v-text-field
          dense
          outlined
          v-else-if="dataField.FieldType == 'textfield'"
          :readonly="dataField.Readonly == 'Y' ? trueValue : falseValue"
          :filled="dataField.Readonly == 'Y' ? trueValue : falseValue"
          v-model="record[dataField.FieldName]"
          autocomplete="nope"
          :label="dataField.Label"
        ></v-text-field>

        <text-area
          v-if="dataField.FieldType == 'textarea'"
          :setup="dataField"
        ></text-area>

        <v-autocomplete
          v-if="dataField.FieldName == 'Series'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="Series"
          label="Num Series"
          :items="seriesData"
          item-text="SeriesName"
          item-value="id"
          @change="setDocNumber(Series)"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'BPLId'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="BPLId"
          label="Branch"
          :items="branchesData"
          item-text="BPLName"
          item-value="BPLId"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'PriceList'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="GroupNum"
          label="Price List"
          :items="pricelistData"
          item-text="ListName"
          item-value="id"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'FromWhsCod'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="FromWhsCod"
          label="From Warehouse"
          :items="warehouses"
          item-value="WhsCode"
          :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
        ></v-autocomplete>

        <v-autocomplete
          v-else-if="dataField.FieldName == 'ToWhsCode'"
          dense
          outlined
          v-show="dataField.FieldType == 'select'"
          v-model="ToWhsCode"
          label="To Warehouse"
          :items="warehouses"
          item-value="WhsCode"
          :item-text="(item) => item.WhsCode + '  -  ' + item.WhsName"
        ></v-autocomplete>

        <DocDatePicker
          v-if="dataField.FieldType == 'date'"
          @date="setDate"
          :myDate="record[dataField.FieldName]"
          :fieldName="dataField.FieldName"
          :title="`${dataField.Label}`"
        ></DocDatePicker>
      </v-col>
    </v-row>
    <!-- end of header fields -->

    <!-- start of bp modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="accent">
          <v-toolbar-title>
            <v-btn icon dark @click="bpDialog = false">
              <v-icon dark>mdi-keyboard-backspace</v-icon> </v-btn
            >Select Customer
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="bpDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Customers Using Card Code or Customer Name"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clicked"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  hide-default-footer
                  class="elevation-1"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of bp modal-->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    headerFields: {
      type: Array,
    },
    seriesData: {
      type: Array,
    },
    branchesData: {
      type: Array,
    },
    initial: {
      type: Object,
    },
    defaultSeries: {
      type: Object,
      required: true,
    },
    DefaultBPLId: {
      type: String,
    },
    usersData: {
      type: Array,
    },
    deparmentData: {
      type: Array,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    activeObject: {
      type: String,
    },
    defaultRequester: {
      type: Number,
    },
  },
  data() {
    return {
      warehouses: [],
      cardCodeIsReadOnly: true,
      search: null,
      activeSeries: null,
      dealerDiscount: null,
      docReadonly: "N",
      bpMasterData: [],
      trueValue: true,
      falseValue: false,
      tenant: {},
      record: {},
      DocNum: "",
      BPLId: null,
      NumAtCard2: null,
      U_SaleType: null,
      U_PCash: null,
      LicTradNum: null,
      U_CashName: null,
      U_CashNo: null,
      Requester: null,
      U_IDNo: null,
      NumAtCard: null,
      Series: null,
      FromWhsCod: null,
      ToWhsCode: null,
      selected: [],
      branches: [],
      delears: [],
      paymentTerms: [],
      bpDialog: false,
      itemsPerPage: 100,
      singleSelect: true,
      ReqType: 12,
      Department: null,
      // ohems: [],
      pricelistData: [],
      GroupNum: null,
      curSources: [
        { name: "Local Currency", value: "L" },
        { name: "System Currency", value: "S" },
        { name: "Bp Currency", value: "C" },
      ],
      status: [
        { name: "Open", value: "O" },
        { name: "Closed", value: "C" },
      ],
      bpHeaders: [
        { text: "Code", value: "CardCode" },
        { text: "Name", value: "CardName" },
      ],

      docTypes: [
        { name: "Items", value: "I" },
        { name: "Service", value: "S" },
      ],

      reqTypes: [
        { name: "User", value: 12 },
        { name: "Employee", value: 171 },
      ],
    };
  },
  watch: {
    defaultSeries: {
      handler: "setSeries",
      immediate: true,
    },
    tenant: {
      handler: "setTenant",
      immediate: true,
    },
    Series: {
      handler: "setSelectedSeries",
      immediate: true,
    },
    BPLId: {
      handler: "setSelectedBranch",
      immediate: true,
    },
    NumAtCard2: {
      handler: "setSelectedDealer",
      immediate: true,
    },
    U_SaleType: {
      handler: "setSaleType",
      immediate: true,
    },
    DefaultBPLId: {
      handler: "setDefaultBPLId",
      immediate: true,
    },

    initial: {
      handler: "setInitial",
      immediate: false,
    },
    record: {
      handler: "setHeaderRecord",
      immediate: true,
    },
    ReqType: {
      handler: "setSelectedReqType",
      immediate: true,
    },

    LicTradNum: {
      handler: "setLicTradNum",
      immediate: true,
    },

    NumAtCard: {
      handler: "setNumAtCard",
      immediate: true,
    },
    GroupNum: {
      handler: "setGroupNum",
      immediate: true,
    },
    ToWhsCode: {
      handler: "setToWhsCode",
      immediate: true,
    },
    FromWhsCod: {
      handler: "setFromWhsCod",
      immediate: true,
    },
    search: {
      handler: "getBpMaster",
      immediate: true,
    },
  },
  methods: {
    setTenant(val) {
      this.$emit("tenant", val);
    },

    setDefaultBPLId(val) {
      if (val) {
        if (this.record.id == null) {
          this.BPLId = val;
        }
      }
    },

    setInitial(val) {
      if (val) {
        if (val.ExtRef) {
          this.cardCodeIsReadOnly = true;
        }

        this.BPLId = val.BPLId;

        this.NumAtCard2 = val.NumAtCard2;
        this.LicTradNum = val.LicTradNum;
        this.ToWhsCode = val.ToWhsCode;
        this.FromWhsCod = val.Filler;
        this.NumAtCard = val.NumAtCard;
        if (!this.docCreate && !this.docCopyTo) {
          this.DocNum = val.DocNum;
          this.Series = val.Series;
        }

        this.record = { ...val };
      }
    },

    setHeaderRecord(val) {
      this.$emit("headerRecord", val);
    },
    setDate(data) {
      const fieldName = data.fieldName;

      if (this.activeObject == 13 || this.activeObject == 14) {
        if (this.tenant.octg != "undefined" && fieldName == "DocDate") {
          var d = new Date(data.date);
          d.setDate(d.getDate() + 30);
          const DocDueDate = d.toISOString().substr(0, 10);

          const details = {
            DocDueDate: DocDueDate,
          };
          const original = this.record;
          this.record = { ...original, ...details };
        }
      }
      this.record = { ...this.record, [data.fieldName]: data.date };
    },
    setSelectedSeries(val) {
      this.$emit("selectedSeries", val);
    },

    setSelectedDealer(val) {
      this.$emit("selectedDealer", val);
    },
    setSelectedReqType(val) {
      this.$emit("selectedReqType", val);
    },

    setSelectedBranch(val) {
      this.$emit("selectedBranch", val);
      if (val) {
        this.$store
          .dispatch("get", `/dealer_masterdata?branchID=${val}`)
          .then((res) => {
            this.delears = res.ResponseData;
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
        this.$store
          .dispatch("get", `/warehouse?branchID=${val}`)
          .then((res) => {
            this.warehouses = res.ResponseData;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setSaleType(val) {
      this.$emit("selectedSaleType", val);
    },
    setLicTradNum(val) {
      this.$emit("setLicTradNum", val);
    },

    setNumAtCard(val) {
      this.$emit("setNumAtCard", val);
    },
    setGroupNum(val) {
      this.$emit("setGroupNum", val);
    },
    setToWhsCode(val) {
      this.$emit("setToWhsCode", val);
    },
    setFromWhsCod(val) {
      this.$emit("setFromWhsCod", val);
    },
    setSeries(val) {
      if (val) {
        if (val.NextNumber) {
          this.record.DocNum = val.NextNumber;
          this.DocNum = val.NextNumber;
          this.activeSeries = val.id;
          this.Series = val.id;
        }

        if (val.IsManual == "Y") {
          this.docReadonly = "N";
        } else {
          this.docReadonly = "Y";
        }
      }
    },
    openBp() {
      if (this.docCreate) {
        this.selected = [];
        this.bpDialog = true;
      }
    },
    getData(data) {
      return this.$data[data];
    },
    clicked($event) {
      this.tenant = $event.item;
      const selectedTenant = $event.item;

      const details = {
        CardCode: selectedTenant.CardCode,
        CardName: selectedTenant.CardName,
      };
      this.LicTradNum = selectedTenant.LicTradNum;

      //DEMO AGRISCOPE REMOVE THIS
      this.GroupNum = selectedTenant.ListNum;

      var d = new Date();
      if (selectedTenant.octg) {
        if (this.activeObject == 13 || this.activeObject == 14) {
          d.setDate(d.getDate() + selectedTenant.octg.ExtraDays);
          var newDate = d.toISOString().substr(0, 10);
          details.DocDueDate = newDate;
        }
      }

      if (this.activeObject == 15) {
        const DocDueDate = d.toISOString().substr(0, 10);
        details.DocDueDate = DocDueDate;
      }
      const original = this.record;
      this.record = { ...original, ...details };
      this.bpDialog = false;
    },
    setDocNumber(id) {
      const seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      console.log("Payment Data");
      if (seriesRecord.IsManual === "Y") {
        this.docReadonly = "N";
        this.record = { ...this.record, DocNum: null };
      } else {
        this.docReadonly = "Y";
        this.DocNum = seriesRecord.NextNumber;
        this.record = { ...this.record, DocNum: seriesRecord.NextNumber };
      }
      this.activeSeries = seriesRecord.id;
    },
    setDefaults() {
      const date = new Date().toISOString().substr(0, 10);
      const details = {
        DocDate: date,
        TaxDate: date,
        CurSource: "L",
        DocStatus: "Open",
      };

      if (this.docCreate && this.activeObject == 205) {
        details.DocDueDate = date;
      }

      this.record = { ...details };
    },
    getBpMaster() {
      const self = this;
      this.$store
        .dispatch("get", `/getCustomers?f=${this.search}`)
        .then((res) => {
          self.bpMasterData = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPriceList() {
      const self = this;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.pricelistData = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  computed: {
    ...mapGetters("ohem", ["ohems"]),
    // globalWarehouses() {
    //   return this.$store.state.globalWarehouses;
    // },
  },
  created() {
    // this.getEmployees();
    this.setDefaults();
    // this.getBpMaster();
    this.getPriceList();
  },
};
</script>

<style lang="scss" scoped>
</style>