<template>

  <v-form ref="form">
    <v-card>

      <!-- start of toolbar 1 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>
          <span v-show="docCreate">Create</span>
          {{objectName}}
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          color="red"
          icon
          @click="openClosePageConfirmationDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="$store.state.loader"
        >
          <v-icon
            left
            dark
          >mdi-upload</v-icon>Submit
        </v-btn>
        <!-- <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="draftLoader"
        >
          <v-icon
            left
            dark
          >mdi-content-save</v-icon>Save As Draft
        </v-btn> -->

        <v-col cols="2">
          <sale-report v-show="this.initialRecord.printUnsyncDocs != 1"></sale-report>
        </v-col>
        <v-btn
          icon
          :to="`/form/settings/${activeObject}`"
        >
          <v-icon color="red">mdi-amplifier</v-icon>
        </v-btn>
        <!-- <v-btn
          text
          @click="viewJournalEntry"
          color="primary"
        >
          <v-icon left>mdi-checkbook</v-icon>Journal Entry
        </v-btn> -->
        <v-spacer></v-spacer>

      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>


        <!-- start of tabs -->
        <v-row v-if="setup.tabs">
          <v-col cols="12">
            <v-tabs
              background-color="accent"
              color="white"
            >
              <!-- loop tabs -->
              <v-tab
                v-for="(tab, index) in setup.tabs"
                v-bind:key="index"
              >{{tab.Label}}</v-tab>
              <!-- loop tab items -->
              <v-tab-item>
                <!-- header section -->
                <header-editor
                    @tenant="setTenantData"
                    @selectedSeries="setSeries"
                    @selectedBranch="setBranch"
                    @selectedRequester="setRequester"
                    @selectedSaleType="setSaleType"
                    @selectedDealer="setDealer"
                    @selectedReqType="setReqType"
                    @selectedDepartment="setDepartment"
                    @headerRecord="setHeaderRecord"
                    @setDealerDiscount="setDealerDiscount"
                    @selectedPettyCash="setPettyCash"
                    @setLicTradNum="setLicTradNum"
                    @setU_CashName="setU_CashName"
                    @setU_CashNo="setU_CashNo"
                    @setU_IDNo="setU_IDNo"
                    @setNumAtCard="setNumAtCard"
                    @setGroupNum="setGroupNum"
                    @setFromWhsCod="setFromWhsCod"
                    @setToWhsCode="setToWhsCode"
                    :defaultSeries="defaultSeries"
                    :DefaultBPLId="DefaultBPLId"
                    :defaultRequester="defaultRequester"
                    :headerFields="setup.HeaderFields"
                    :seriesData="seriesData"
                    :branchesData="branchesData"
                    :usersData="usersData"
                    :deparmentData="deparmentData"
                    :docCreate="docCreate"
                    :docCopyTo="docCopyTo"
                    :activeObject="activeObject"
                    :initial="initialRecord"
                ></header-editor>
                <!-- end of header section -->

                <!-- start of footer editor -->
                <footer-editor
                    @discount="setGenDiscount"
                    @SaleEmployee="setSaleEmployee"
                    @ExtRef="setExtRef"
                    @selectedSalePipe="setSalePipe"
                    @OwnerCode="setOwnerCode"
                    @Comments="setComments"
                    @U_ServiceCall="setServiceCall"
                    @U_MpesaRefNo="setMpesaRefNo"
                    @U_DemoLocation="setDemolocation"
                    @U_Technician="setTechnician"
                    @U_Location="setLocation"
                    @U_PCash="requestType"
                    @U_SSerialNo="setItemSerial"
                    @U_transferType="setTransferType"
                    @U_TypePur="setPurchaseType"
                    @setU_NegativeMargin="setU_NegativeMargin"
                    @salePaymentData="setPaymentData"
                    :docCreate="docCreate"
                    :externalRefrence="currentExtRef"
                    :DefaultSlp="DefaultSlp"
                    :salesEmployees="salesEmployees"
                    :docTotal="docTotal"
                    :genDiscount="genDiscount"
                    :dealerDiscount="dealerDiscount"
                    :discountAmount="discountAmount"
                    :genTotalTax="genTotalTax"
                    :footerFields="setup.FooterFields"
                    :finalTotalAmount="finalTotalAmount"
                    :finalRoundDif="finalRoundDif"
                    :withPayment="withPayment"
                    :activeObject="activeObject"
                    :initial="initialRecord"
                ></footer-editor>
                <!-- end of footer editor -->
              </v-tab-item>
              <v-tab-item
                v-for="(tab, index) in setup.tabs"
                v-bind:key="index"
              >
                <!-- tab editor -->
                <tab-editor
                  :tab="tab"
                  :selectedTenant="selectedTenant"
                  :NewBPLId="NewBPLId"
                  @saleItemsData="setSaleData"
                  @JrnlMemo="setJrnlRemarks"
                  @attachments="setAttchments"
                  @UseShpdGd="setShpdGd"
                  @docType="setDocType"
                  :docCreate="docCreate"
                  :docCopyTo="docCopyTo"
                  :initial="initialRecord"
                  :activeObject="activeObject"
                  :dealerDiscount="dealerDiscount"
                  :ToWhsCode="ToWhsCode"
                  :FromWhsCod="FromWhsCod"
                  :initialRows="tableData"
                  :GroupNum="GroupNum"
                ></tab-editor>
                <!-- end of tab editor -->
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <!-- End of tabs -->

        <!-- start of journal entry modal -->
        <v-dialog
          v-model="journalDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="accent"
            >
              <v-toolbar-title>
                <v-btn
                  icon
                  dark
                  @click="journalDialog = false"
                >
                  <v-icon dark>mdi-keyboard-backspace</v-icon>
                </v-btn>Journal Entry
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="journalDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

          </v-card>
        </v-dialog>
        <!-- end of journal entry modal-->

        <!-- closePageConfirmation -->
        <v-dialog
          v-model="closePageConfirmationDialog"
          max-width="650px"
        >
          <v-card>
            <v-toolbar
              dense
              color="red"
              dark
            >
              <v-toolbar-title>Confirmation</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click.native="closePageConfirmationDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    If you leave, your unsaved changes will be lost.continue?
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="red"
                      dark
                      @click="closePageAndRedirectAction"
                    >Yes</v-btn>
                  </v-col>
                </v-row>

              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- closePageConfirmation-->

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import HeaderEditor from "../_components/header-editor.vue";
import FooterEditor from "../_components/footer-editor.vue";
import TabEditor from "../_components/tab-editor.vue";
import SaleReport from "../../../reports/sales-report.vue";
import { addItem } from "../methods/addItems.js";
export default {
  components: {
    HeaderEditor,
    FooterEditor,
    TabEditor,
    SaleReport,
  },
  props: {
    originNo: {
      type: String,
    },
    originName: {
      type: String,
    },
    docCreate: {
      type: Boolean,
    },
    docCopyTo: {
      type: Boolean,
    },
    objectName: {
      type: String,
    },
    activeObject: {
      type: String,
    },
    setup: {
      type: Object,
      required: true,
    },
    initial: {
      type: Object,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    },

    branchesData: {
      type: Array,
      required: true,
    },
    defaultSeries: {
      type: Object,
      required: true,
    },
    tableData: {
      type: Array,
    },
    usersData: {
      type: Array,
    },
    deparmentData: {
      type: Array,
    },
    withPayment: {
      type: String,
    },
  },
  computed: {
    discountAmount() {
      let discount = 0;
      if (this.genDiscount) {
        let discPerct = parseInt(this.genDiscount);
        if (discPerct > 0) {
          discount = (discPerct / 100) * this.docTotal;
        }
      }
      return discount;
    },

    docTotal() {
      let total = 0;
      if (
        typeof this.saleItemsData != "undefined" &&
        this.saleItemsData.length > 0
      ) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.LineTotal;

            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    totalTax() {
      let total = 0;
      if (
        typeof this.saleItemsData != "undefined" &&
        this.saleItemsData.length > 0
      ) {
        return this.saleItemsData
          .map((item) => {
            const amount = item.VatSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    genTotalTax() {
      let total = 0;
      let totalTax = this.totalTax;
      let discount = 0;
      if (this.genDiscount) {
        discount = parseFloat(this.genDiscount);
        total = totalTax - (discount / 100) * totalTax;
      } else {
        total = totalTax;
      }

      return parseFloat(total.toFixed(2));
    },

    genTotalAmount() {
      let total = 0;
      let docTotal = this.docTotal;
      let discount = 0;
      if (this.genDiscount) {
        discount = parseFloat(this.genDiscount);
        total = docTotal - (discount / 100) * docTotal;
      } else {
        total = docTotal;
      }
      return parseFloat(total.toFixed(2));
    },
    finalTotalAmount() {
      var docTotalAmount = this.genTotalTax + this.genTotalAmount;
      var roundedTotal = Number(Math.round(docTotalAmount));

      return roundedTotal.toFixed(2);
    },
    finalRoundDif() {
      var docTotalAmount = this.genTotalTax + this.genTotalAmount;
      var roundedTotal = Number(Math.round(docTotalAmount));
      return (roundedTotal - docTotalAmount).toFixed(2);
    },
    currentExtRef() {
      return this.record.ExtRefDocNum;
    },

    defaultRequester() {
      return this.$store.state.user.id;
    },
  },
  data() {
    return {
      dialog: false,
      loader: false,
      draftLoader: false,
      manualNum: false,
      DefaultSlp: null,
      DefaultBPLId: null,
      initialRecord: {},
      search: null,
      SlpCode: null,
      NewBPLId: null,
      selectedSeries: null,
      searchItem: null,
      activeSeries: null,
      itemDialog: false,
      selectedItems: [],
      itemsPerPage: 20,
      uploadedFiles: {},
      genDiscount: null,
      record: {},
      dialogSetup: [],
      singleSelect: true,
      selectedDocs: [],
      selectedTenant: {},
      saleItemsData: [],
      salesEmployees: [],
      taxes: [],
      docId: null,
      copyDocs: [],
      trueValue: true,
      falseValue: false,
      journalDialog: false,
      dealerDiscount: null,
      RoundDif: null,
      printUnsyncDocs: null,
      defaultSettings: {},
      closePageConfirmationDialog: false,
      GroupNum: null,
      FromWhsCod: null,
      ToWhsCode: null,
    };
  },
  watch: {
    tableData: {
      handler: "setTableData",
      deep: true,
      immediate: true,
    },
    initial: {
      handler: "setInitialRecord",
      immediate: true,
    },
    withPayment: {
      handler: "setWithPayment",
      immediate: true,
    },
  },
  methods: {
    addItem,
    viewJournalEntry() {
      this.journalDialog = true;
    },

    setHeaderRecord(data) {
      this.record = { ...this.record, ...data };
    },
    setSeries(data) {
      this.selectedSeries = data;
    },
    setAttchments(data) {
      this.uploadedFiles = data;
    },
    setBranch(data) {
      this.NewBPLId = data;
      const details = { BPLId: data };
      this.record = { ...this.record, ...details };
    },
    setRequester(data) {
      const details = { Requester: data };
      this.record = { ...this.record, ...details };
    },
    setDealer(data) {
      const details = { NumAtCard2: data };
      this.record = { ...this.record, ...details };
    },
    setSaleType(data) {
      const details = { U_SaleType: data };
      this.record = { ...this.record, ...details };
    },
    setPettyCash(data) {
      const details = { U_PCash: data };
      this.record = { ...this.record, ...details };
    },
    setItemSerial(data) {
      const details = { U_SSerialNo: data };
      this.record = { ...this.record, ...details };
    },
    setSalePipe(data) {
      const details = { U_SalePipe: data };
      this.record = { ...this.record, ...details };
    },
    setTransferType(data) {
      const details = { U_transferType: data };
      this.record = { ...this.record, ...details };
    },
    setPurchaseType(data) {
      const details = { U_TypePur: data };
      this.record = { ...this.record, ...details };
    },
    setU_NegativeMargin(data) {
      const details = { U_NegativeMargin: data };
      this.record = { ...this.record, ...details };
    },
    setOwnerCode(data) {
      const details = { OwnerCode: data };
      this.record = { ...this.record, ...details };
    },
    setDepartment(data) {
      const details = { Department: data };
      this.record = { ...this.record, ...details };
    },
    setReqType(data) {
      const details = { ReqType: data };
      this.record = { ...this.record, ...details };
    },
    requestType(data) {
      const details = { U_PCash: data };
      this.record = { ...this.record, ...details };
    },

    setComments(data) {
      const details = { Comments: data };
      this.record = { ...this.record, ...details };
    },
    setServiceCall(data) {
      const details = { U_ServiceCall: data };
      this.record = { ...this.record, ...details };
    },
    setJrnlRemarks(data) {
      const details = { JrnlMemo: data };
      this.record = { ...this.record, ...details };
    },
    setShpdGd(data) {
      const details = { UseShpdGd: data };
      this.record = { ...this.record, ...details };
    },
    setDocType(data) {
      const details = { DocType: data };
      this.record = { ...this.record, ...details };
    },
    setDemolocation(data) {
      const details = { U_DemoLocation: data };
      this.record = { ...this.record, ...details };
    },
    setMpesaRefNo(data) {
      const details = { U_MpesaRefNo: data };
      this.record = { ...this.record, ...details };
    },
    setTechnician(data) {
      const details = { U_Technician: data };
      this.record = { ...this.record, ...details };
    },
    setLocation(data) {
      const details = { U_Location: data };
      this.record = { ...this.record, ...details };
    },
    setGenDiscount(data) {
      this.genDiscount = parseInt(data);
    },
    setSaleEmployee(data) {
      const details = { SlpCode: data };
      this.record = { ...this.record, ...details };
    },
    setNumAtCard(data) {
      const details = { NumAtCard: data };
      this.record = { ...this.record, ...details };
    },

    setExtRef(data) {
      const details = { ExtRef: data };
      this.record = { ...this.record, ...details };
    },
    setGroupNum(data) {
      if (data) {
        this.GroupNum = data;
      }
    },
    setSaleData(data) {
      if (data.length == 0) {
        return;
      }
      // console.log(data, 'data');
      // console.log(this.saleItemsData, 'sale items');
      this.saleItemsData = [...data];
      // console.log(this.saleItemsData, 'update items')
      // this.saleItemsData = data;
    },
    setTenantData(data) {
      this.selectedTenant = data;
    },
    setDealerDiscount(data) {
      this.dealerDiscount = data;
    },
    getData(data) {
      return this.$data[data];
    },
    setTableData(val) {
      this.saleItemsData = val;
    },
    setWithPayment(val) {
      const details = { IsICT: val };
      this.record = { ...this.record, ...details };
    },
    setLicTradNum(val) {
      const details = { LicTradNum: val };
      this.record = { ...this.record, ...details };
    },
    setU_CashName(val) {
      const details = { U_CashName: val };
      this.record = { ...this.record, ...details };
    },
    setU_CashNo(val) {
      const details = { U_CashNo: val };
      this.record = { ...this.record, ...details };
    },
    setU_IDNo(val) {
      const details = { U_IDNo: val };
      this.record = { ...this.record, ...details };
    },

    setPaymentData(data) {
      const details = { payment: data };
      this.record = { ...this.record, ...details };
    },
    setToWhsCode(val) {
      this.ToWhsCode = val;
      const details = { ToWhsCode: val };
      this.record = { ...this.record, ...details };
    },
    setFromWhsCod(val) {
      this.FromWhsCod = val;
      const details = { FromWhsCod: val };
      this.record = { ...this.record, ...details };
    },

    setInitialRecord(val) {
      if (val) {
        this.record = { ...val };
        this.initialRecord = { ...val };
      }
    },
    sendData() {
      const data = this.record;
      data.CardCode = this.selectedTenant ? this.selectedTenant.CardCode : null;
      data.Series = this.selectedSeries;
      data.DocTotal = this.finalTotalAmount;
      data.VatSum = this.genTotalTax;
      data.DiscPrcnt = this.genDiscount || 0;
      data.DiscSum = this.discountAmount || 0;
      data.document_lines = this.saleItemsData;
      data.oats = this.uploadedFiles;
      this.$store.commit("loader", true);
      this.$emit("data", data);
    },
    openItemModal() {
      this.selectedItems = [];
      this.itemDialog = true;
    },
    openModal(data) {
      this.dialogSetup = data;
      this.dialog = true;
    },
    closeModal(closeStatus) {
      this.dialog = closeStatus;
    },
    setDate() {},
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {},
    getTaxGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/taxgroups/output`)
        .then((res) => {
          self.taxes = res;
          console.log("tax groups done")
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSalesEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    generalSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          self.defaultSettings = res.ResponseData;
          // set default slp
          if (res.ResponseData.DfltSlp !== null) {
            self.DefaultSlp = res.ResponseData.DfltSlp;
          }
          self.DefaultBPLId = res.ResponseData.DefaultBPLId;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    closePageAndRedirectAction() {
      this.$router.push(`/inventory/transactions/${this.activeObject}`);
    },
    openClosePageConfirmationDialog() {
      if (this.docCreate) {
        this.closePageConfirmationDialog = true;
      } else {
        this.$router.push(`/inventory/transactions/${this.activeObject}`);
      }
    },
  },
  created() {
    this.getTaxGroups();
    this.getSalesEmployees();
    this.generalSettings();
  },
};
</script>


    

